import React, { useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper"; // <-- Import Paper
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Input = styled("input")({
  display: "none",
});

const renderJson = (data, depth = 0) => {
  return Object.entries(data).map(([key, value]) => (
    <Box key={key} sx={{ ml: depth * 2 }}>
      <Typography variant="body2">
        {key}:{" "}
        {typeof value === "object" ? (
          <span style={{ color: "blue" }}>{`{}`}</span> // Style for objects
        ) : Array.isArray(value) ? (
          <span style={{ color: "green" }}>{`[]`}</span> // Style for arrays
        ) : (
          <span style={{ color: "black" }}>{JSON.stringify(value)}</span>
        )}
      </Typography>
      {typeof value === "object" && value !== null ? (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">{"{"}</Typography>
          {renderJson(value, depth + 1)}
          <Typography variant="body2">{"}"}</Typography>
        </Box>
      ) : null}
    </Box>
  ));
};
const renderJsonToTable = (data) => {
  let tableRows = [];

  const generateRows = (data, prefix = "") => {
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        generateRows(value, `${prefix}${key}.`);
      } else {
        tableRows.push({ key: `${prefix}${key}`, value: value });
      }
    });
  };

  generateRows(data);

  const halfLength = Math.ceil(tableRows.length / 2);
  let combinedRows = [];

  for (let i = 0; i < halfLength; i++) {
    const leftData = tableRows[i];
    const rightData = tableRows[i + halfLength];
    combinedRows.push({
      key1: leftData ? leftData.key : "",
      value1: leftData ? leftData.value : "",
      key2: rightData ? rightData.key : "",
      value2: rightData ? rightData.value : "",
    });
  }

  return (
    <TableContainer
      style={{ fontFamily: "Roboto" }} // Add style to TableContainer
      component={Paper}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: 600,
              }}
            >
              Key 1
            </TableCell>
            <TableCell>Value 1</TableCell>
            <TableCell
              sx={{
                fontWeight: 600,
              }}
            >
              Key 2
            </TableCell>
            <TableCell>Value 2</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {combinedRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  fontWeight: 600,
                }}
              >
                {row.key1.replace(/_/g, " ")}
              </TableCell>
              <TableCell>{row.value1}</TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                }}
              >
                {row.key2.replace(/_/g, " ")}
              </TableCell>
              <TableCell>{row.value2}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function UploadForm() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null); // New state variable for file name
  const [jsonContent, setJsonContent] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const [recordedBlob, setRecordedBlob] = useState(null);
  const mediaRecorderRef = useRef(null);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    const chunks = [];
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/wav" });
      setRecordedBlob(blob);
      setFile(blob);
      setFileName("Recorded Audio.wav");
    };
    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };
  // ... rest of your code

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : null); // Update file name
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading to true when submitting

    const formData = new FormData();
    formData.append("audio_file", file);

    try {
      const response = await fetch(
        "https://reminiscemebackend.brendanlarkin.com/",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      const result = await response.json();
      console.log(result);

      const jsonUrl = result.outputFileUrl;
      const jsonResponse = await fetch(jsonUrl);
      const jsonContent = await jsonResponse.json();
      console.log(jsonResponse); // Log the entire response object

      setJsonContent(jsonContent);
      console.log(jsonContent); // Log the JSON content
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false); // Set loading to false when request is completed

    setFile(null); // Reset file
    setFileName(null); // Reset file name

    const renderJson = (data, depth = 0) => {
      return Object.entries(data).map(([key, value]) => (
        <Box key={key} sx={{ ml: depth * 2 }}>
          <Typography variant="body2">
            {key}: {typeof value === "object" ? "" : value}
          </Typography>
          {typeof value === "object" && value !== null
            ? renderJson(value, depth + 1)
            : null}
        </Box>
      ));
    };
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2, // Adjust as needed for spacing
        paddingTop: 5, // Adjust as needed for spacing
        paddingBottom: 0, // Adjust as needed for spacing
        fontFamily: "Roboto",
        fontSize: "3rem",
      }}
    >
      Record Audio Below, or Upload a File
      {isRecording ? (
        <IconButton color="secondary" onClick={stopRecording}>
          <StopIcon />
        </IconButton>
      ) : (
        <IconButton
          color="primary"
          variant="contained"
          onClick={startRecording}
        >
          <MicIcon />
        </IconButton>
      )}
      <label htmlFor="contained-button-file">
        <Input
          accept="audio/*"
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <Button variant="contained" component="span">
          Choose File
        </Button>
      </label>
      {fileName && (
        <Typography variant="subtitle1">Selected File: {fileName}</Typography>
      )}
      <Button type="submit" variant="contained" disabled={isLoading}>
        {isLoading ? "Loading..." : "Submit"}{" "}
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          setFile(null); // Reset file
          setFileName(null); // Reset file name
        }}
      >
        Reset
      </Button>
      {jsonContent && (
        <Paper
          sx={{
            p: 2,
            maxWidth: "80%",
            overflowX: "auto",
            backgroundColor: "#f5f5f5",
            mt: 2,
            fontFamily: "Roboto",
          }}
        >
          <Typography variant="h6">User Info</Typography>
          {renderJsonToTable(jsonContent)}
        </Paper>
        
      )}
      
      
    </Box>
  );
}

export default UploadForm;
