import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@mui/system";

import UploadForm from "./Components/UploadForm";
import Questions from "./Components/Questions";
import { Box } from "@mui/material";
import LandingPage from "./Views/LandingPage";

function App() {
  return (
    <div>
      {/* <LandingPage ddd/> */}
      <UploadForm />

      
      <Questions />
    </div>
  );
}

export default App;
